.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 2vh;
  left: 25%;
  width: 50%;
  height: 90vh;
  z-index: 100;
  overflow: hidden;
  background: white;
  border-radius: 10px;
}

.modalContent {
    height: 90vh;
    overflow-y: auto;
  }

.leftContent {
  float: left;
  margin: 1rem;
}

.leftContent img {
  width: 440px;
}

.rightContent{
    padding:2rem;
}

.rightContent p,
.rightContent h3,
.rightContent ul li,
.rightContent a {
  color: black;
  font: "Poppins", sans-serif;
  padding-left:10px;
}

.rightContent ul li{
    list-style: inside;
    padding:2px;
}

.rightContent p, .rightContent ul li {
    font-size: small;
}

.rightContent h3 {
  font-size: x-large;
}

.exitButton {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  margin: 5px 25px;
  font-size: x-large;
  color: black;
}

@media (max-width: 1400px) {
  .leftContent {
    float: none;
  }
  .leftContent img {
    width: 80%;
    margin-left: 10%;
  }
  .modalContent {
    height: 90vh;
    overflow-y: auto;
  }
  .rightContent {
    margin: 10px;
  }

  .rightContent p {
    font-size: small;
  }

  .exitButton{
    margin: 5px 25px;
  }
}

@media (max-width: 768px) {
    .modal{
        width: 90%;
        left:5%;
    }
}