.footer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #424852;
}

.social{
    margin: 1rem;
}

.social a{
    padding:10px;
}

.social a:hover{
    opacity: .8;
}